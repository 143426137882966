/* eslint-disable no-unused-labels */
import React, { useEffect, useState, } from "react";
import { Spin, Input, Card, Button, Col, Row, Form, Select, message, Image, Modal, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import { get_Deposit_Mode_Name, get_Revision_Details, get_Sales_Details, get_Crew_Details, update_mismatch_details, get_Outlet_Name, get_Attachement_Details, update_dayclosure_verification, get_Reject_Reason, getDenominationName } from "../../../@app/master/masterSlice";
// import { postDayClosureDetails } from "../../../@app/master/masterSlice";
// import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

function CashHandlingForm() {
  const dispatch = useDispatch();
  // const [form] = Form.useForm();
  const { Option } = Select;
  const { control, } = useForm();
  const location = useLocation();
  const { state } = location;
  // let defaultValue = state?.data;
  const navigate = useNavigate();

  //for geting value from previous page
  const parsedDenominations = JSON.parse(state?.denominations);
  const ClosureStatus = state.status;
  const [UtrNum, setUtrNum] = useState('');
  const getUtrNum = (i) => { setUtrNum(i.target.value) }
  const [revRemarks, setrevRemarks] = useState('');
  const getRemarks = (i) => { setrevRemarks(i.target.value) }

  const WrongDepositDate = state?.deposit_date
    ? state?.deposit_date.split('-').reverse().join('-')
    : ''; // Reformat the date

  const [rejectdropdown, setrejectdropdown] = useState([]);
  useEffect(() => {
    dispatch(get_Reject_Reason())
      .then((result) => {
        setrejectdropdown(result.data);
      })
  }, [dispatch]);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [skipdropdown, setskipdropdown] = useState([]);

  useEffect(() => {
    dispatch(getDenominationName())
      .then((result) => {
        if (result && Array.isArray(result.data)) {
          const defTitle3Objects = result.data.filter((item) => item.def_title_id === 3);
          const defTitle4Objects = result.data.filter((item) => item.def_title_id === 4);
          setskipdropdown(defTitle4Objects);
          setDropdownOptions(defTitle3Objects);
        }
      });
  }, [dispatch]);

  const userData = useSelector((state) => state.auth);

  const [previewOpen, setPreviewOpen] = useState(false);
  const handleCancel = () => setPreviewOpen(false);

  const [previewOpen2, setPreviewOpen2] = useState(false);
  const handleCancel2 = () => setPreviewOpen2(false);

  const [proofimage, setproofimage] = useState('');
  const [proofimage2, setproofimage2] = useState('');
  useEffect(() => {
    dispatch(get_Attachement_Details({ data: { closureid: state?.closure_id } }))
      .then((resData) => {
        if (resData.data) {
          setproofimage(resData.data.proofimage);
          setproofimage2(resData.data.reattachimage);
        }
        setPreviewTitle('proof image');
        setPreviewTitle2('attchment');
      })
  }, [dispatch]);

  const [DepositAmt, setDepositAmt] = useState('0.00');

  const getDepositAmt = (i) => {
    const inputAmount = parseFloat(i.target.value);
    setDepositAmt(inputAmount.toFixed(2)); // Format to two decimal places

    const actualDepositAmt = parseFloat(state?.deposit_amount);
    setenterDiffAmt((inputAmount - actualDepositAmt).toFixed(2)); // Format to two decimal places
  }

  const [enterDiffAmt, setenterDiffAmt] = useState('0.00');
  const [rejectReason, setrejectReason] = useState([]);
  const [refNum1, setRefNum2] = useState('-');

  const [lastclosuredate, setlastclosuredate] = useState(null);

  useEffect(() => {
    dispatch(get_Sales_Details({ data: { outlet_code: state?.outlet_code, closuredate: state?.sales_closure_date } }))
      .then((response) => {
        if (response && response.data.lastclosuredate) {
          setlastclosuredate(response.data.lastclosuredate);
        } else {
          setlastclosuredate('-');
        }
      })
  }, [dispatch]);

  const [revisionData, setrevisionData] = useState('');
  const [ActAmt, setActAmt] = useState('');
  const [DiffAmt, setDiffAmt] = useState('');
  const [misDeposit, setmisDeposit] = useState('');
  const [revClosureid, setrevClosureid] = useState('');
  const [ReDepositmode, setReDepositmode] = useState('');

  useEffect(() => {
    setShowLoader(true);
    const outlet_code = state?.outlet_code;
    dispatch(get_Revision_Details({ data: { outlet_code: outlet_code } }))
      .then((revData) => {
        if (revData && Array.isArray(revData.data) && revData.data.length > 0) {
          setrevisionData(revData.data[0]);
          setActAmt(revData.data[0].actual_deposit_amt);
          setDiffAmt(revData.data[0].mismatch_amt);
          setrevClosureid(revData.data[0].sales_closure_id);
          setRefNum2(revData.data[0].reference_num);
          setReDepositmode(revData.data[0].deposit_mode);
          setmisDeposit(parseFloat(revData.data[0].system_deposit_amt) - parseFloat(revData.data[0].actual_deposit_amt));
        }
      })
  }, [dispatch]);

  const [crewName, setcrewName] = useState([]);
  const [error, setError] = useState(null);
  const userid = ''; // Define your userid here

  useEffect(() => {
    dispatch(get_Crew_Details({ data: { outletid: userid } }))
      .then((result) => {
        if (result && result.data && Array.isArray(result.data)) {
          setcrewName(result.data);
        } else {
          setError('Data received from API is not in the expected format');
        }
      })
      .catch((error) => {
        setError('Error in API call: ' + error.message);
      });
  }, [dispatch, userid]);


  function useMapping(dispatch, getDataFunction, id) {
    const [mapping, setMapping] = useState({});
    useEffect(() => {
      dispatch(getDataFunction())
        .then((result) => {
          const data = result.data;
          if (typeof data === 'object' && Object.keys(data).length > 0) {
            const formattedData = {};
            for (const key in data) {
              if (data[key] !== undefined) {
                formattedData[key] = data[key].name;
              }
            }
            setMapping(formattedData);
          }
        });
    }, [dispatch]);
    return mapping[id];
  }
  const OutletName = useMapping(dispatch, get_Outlet_Name, state?.outlet_code);
  // const closureEmpName = useMapping(dispatch, getEmployeeMaster, state?.closureby);

  const mismatchDepositData = async () => {

    setShowLoader(true);
    Modal.confirm({
      title: 'Confirmation',
      content: 'Do you want to continue? Verify',
      onOk() {

    const oldClosure_id = revClosureid;
    const deposit_amount = misDeposit;
    const Reremarks = revRemarks;
    const closure_by = userData.userData.data?.id;
    const backoffice = 'Yes';
    const waiting_at = '7';

    // Update day closure details for non-deposit
    dispatch(update_mismatch_details({
      data: {
        oldClosure_id, backoffice, deposit_amount,
        waiting_at, closure_by, Reremarks
      },
    }))
      .then((response) => {
        if (response.status === 200) {
          navigate('/backOffice');
          message.success({ content: 'Verified Successfully ' });
        } else {
          message.error({ content: 'Verification Failed' });
        }
      });
    }
  })
    setShowLoader(false);
  };


  const [previewTitle, setPreviewTitle] = useState('');
  const [previewTitle2, setPreviewTitle2] = useState('');

  function useMapping2(dispatch, getDataFunction, id) {
    const [mapping, setMapping] = useState({});
    useEffect(() => {
      dispatch(getDataFunction())
        .then((result) => {
          const data = result.data;
          if (typeof data === 'object' && Object.keys(data).length > 0) {
            const statusMasterData = {};
            for (const key in data) {
              if (data[key] !== undefined) {
                statusMasterData[data[key].def_list_code] = data[key].def_list_name;
              }
            }
            setMapping(statusMasterData);
          }
        });
    }, [dispatch]);

    return mapping[id];
  }
  const depositModeName = useMapping2(dispatch, get_Deposit_Mode_Name, state?.deposit_mode);

  const antIcon = (<LoadingOutlined style={{ fontSize: 34, }} spin />);

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const delay = [1, 2, 3, 4, 10].includes(ClosureStatus) ? 2000 : 7000;
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [ClosureStatus]);

  const onClickBack = () => {
    navigate('/backOffice');
  };

  const handleSaveVerificationData = async () => {

    setShowLoader(true);
    const salesClosure_id = state?.closure_id;
    const waiting_At = '9';
    const closure_by = userData.userData.data?.id;
    const utr_No = UtrNum;

    dispatch(update_dayclosure_verification({ data: { closure_by, salesClosure_id, waiting_At, utr_No } }))
      .then((response) => {
        if (response.status === 200) {
          navigate('/backOffice');
          message.success({ content: 'Cash Deposit Information Verified', })
        } else {
          message.error({ content: 'Cash Deposit Request Failed', })
        }
      });

    setShowLoader(false);
  };
  const handleSaveRejectionData = async () => {
    setShowLoader(true);
    try {
      let closure_by; // Declare closure_by outside the if/else blocks
      if (rejectReason.includes("1") && DepositAmt == '0.00') {
        message.error({ content: 'Please Check Differnce Amount ', })
      } else {
        if (userData.userData.data?.id == null) {
          closure_by = '0';
        } else {
          closure_by = userData.userData.data?.id;
        }
        const outlet_code = state?.outlet_code;
        const salesClosure_id = state?.closure_id;
        const Deposit_Amt = state?.deposit_amount;
        const reject_Reason = rejectReason;
        const ActDeposit = DepositAmt;
        const Diff_Amt = enterDiffAmt;
        const utr_No = UtrNum;
        const waiting_At = '8';

        const response = await dispatch(update_dayclosure_verification({ data: { ActDeposit, closure_by, Diff_Amt, outlet_code, Deposit_Amt, utr_No, reject_Reason, salesClosure_id, waiting_At } }));

        if (response.status === 200) {
          navigate('/backOffice');
          message.success({ content: 'Cash Deposit Rejected', })
        }
      }
    } catch (error) {
      // Handle errors if necessary
      message.error({ content: 'Cash Deposit Rejected Failed', })
    } finally {
      setShowLoader(false);
    }
  };



  return (
    <>
      <div className="content-container">
        {showLoader && (
          <div className="loader-container">
            <Spin indicator={antIcon} />
          </div>
        )}
        <div className={`actual-content ${showLoader ? 'blurred' : ''}`}>

          <Card>
            <Row style={{ justifyContent: "center" }}>

              <Col span={24}>
                <Form
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                >

                  <div className=" justify-content-center align-items-center "  >
                    <Row >
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="outlet_name" label="Outlet Name" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}  >
                          <Controller
                            control={control}
                            name="outlet_name"
                            render={() => (<Input value={OutletName} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item
                          name="orl_name"
                          label="ORL Name"
                          labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}
                        >
                          <Controller
                            control={control}
                            name="orl_name"
                            render={() => (
                              <Input
                                value={state?.outlet_orl_name}
                                readOnly
                                className="blackinputbox"
                              />
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="preSalesDate" label="Pre. Acc. Close" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }} >
                          <Controller
                            control={control}
                            name="orl_name"
                            render={() => (<Input value={lastclosuredate} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item
                          name="Closure_By"
                          label="Closure By"
                          labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}
                          rules={[{required: true,}]}
                        >
                          {error ? ( // Check if there's an error
                            <div className="error-message">{error}</div>
                          ) : (
                            <Select
                              placeholder="Select"
                              defaultValue={state?.closureby !== null ? state?.closureby : ''}
                              disabled={ClosureStatus !== 1 && ClosureStatus !== 3}
                              name="Closure_By"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              style={{ width: '100%' }}
                            >
                              {crewName.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>

                    </Row>
                  </div>
                  <div
                    className=" justify-content-center align-items-center "
                    style={{ width: "100%", }}
                  >

                    <Row>
                      <Col md={{ span: 6 }} xs={{ span: 24 }}  >
                        <Form.Item name="openingBal" label="Opening Balance" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                          <Controller
                            control={control}
                            name="openingBal"
                            render={() => (<Input value={state?.open_balance} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }}  >
                        <Form.Item name="cashSale" label="Cash Sales" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                          <Controller
                            control={control}
                            name="cashSale"
                            render={() => (<Input value={state?.sales_amount} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={{ span: 6 }} xs={{ span: 24 }} >
                        <Form.Item name="totalCash" label=" TOTAL CASH" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                          <Controller
                            control={control}
                            name="totalCash"
                            render={() => (<Input value={state?.closure_amount} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="deposit_by" label="Deposit By" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center', color: 'black' } }} rules={[{required: true,}]}>
                          {error ? ( // Check if there's an error
                            <div className="error-message">{error}</div>
                          ) : (
                            <Select
                              placeholder='Select'
                              disabled={ClosureStatus !== 2 && ClosureStatus !== 5 && ClosureStatus !== 6 && ClosureStatus !== 4}
                              name='deposit_by'
                              defaultValue={state?.depositby !== null ? state?.depositby : 'Admin'}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              style={{ width: '100%', color: 'black' }} // Set the width of the Select component
                            >
                              {crewName.map((item, index) => {
                                return (
                                  <Option
                                    key={index} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          )}
                        </Form.Item>

                      </Col>
                    </Row>
                  </div>

                  <div>
                    <Row justify="center">
                      <Col md={12} xs={24}>

                        <table
                          name="denominationResult"
                          style={{ width: '100%', textAlign: 'center', margin: '0 auto' }}
                        >
                          <thead>
                            <tr>
                              <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Denomination</td>
                              <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Count</td>
                              <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Amount</td>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(parsedDenominations) ? (
                              parsedDenominations.map((item, index) => (
                                <tr key={index + 1}>
                                  <td style={{ width: '33%', border: '2px black solid' }}>{item.def_list_name}</td>
                                  <td style={{ width: '33%', border: '2px black solid' }}>{item.inputvalue || 0}</td>
                                  <td style={{ width: '33%', border: '2px black solid' }}>{item.value || 0}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No data available</td>
                              </tr>
                            )}
                          </tbody>
                          <tfoot>
                            <tr >
                              <td colSpan="2" style={{ backgroundColor: 'black', color: 'orange', fontWeight: 'bolder' }}>Total</td>
                              <td style={{ backgroundColor: 'black', color: 'orange', fontWeight: 'bolder' }}>{state?.denomination_amount}</td>
                              <td ><input type="hidden" defaultValue={state?.denomination_amount} /></td>
                            </tr>
                          </tfoot>
                        </table>
                        <br></br>
                        <>
                          <Col md={24} xs={24}>
                              <Form.Item name="aov" label="AOV Remarks"
                                labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                <Input
                                  defaultValue={state?.aov}
                                  style={{
                                    textAlign: 'center',
                                  }}
                                />
                              </Form.Item>
                          </Col>
                          <Col md={24} xs={24}>
                              <Form.Item name="abc" label="ABC Remarks"
                                labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                <Input
                                  defaultValue={state?.abc}
                                  style={{
                                    textAlign: 'center',
                                  }}
                                />
                              </Form.Item>
                          </Col>
                          <Col md={24} xs={24}>
                              <Form.Item name="remarks" label="Overall Remarks"
                                labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                <Input
                                  defaultValue={state?.genremarks}
                                  style={{
                                    textAlign: 'center',
                                  }}
                                />
                              </Form.Item>
                          </Col>
                        </>
                      </Col>
                      <Col md={12} xs={24}>
                        <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item
                              label="Deposit Amount"
                              labelCol={{ md: { span: 12 }, xs: { span: 24 }, style: { textAlign: 'center' } }}
                            >
                              <Input
                                type="text"
                                readOnly
                                name="depositAmount"
                                defaultValue={state?.deposit_amount}
                                className="blackinputbox"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item name="cashBalance" label="Cash Balance"
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                              <Input
                                name="cashBalance"
                                readOnly
                                defaultValue={state?.balance_amount}
                                className="blackinputbox"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item name="ref_num" label="Reference Num"
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                              <Controller
                                style={{ textAlign: 'center' }}
                                control={control}
                                name="ref_num"
                                render={() => (
                                  <Input
                                    name="ref_num"
                                    readOnly
                                    defaultValue={state?.referencenum !== null ? state?.referencenum : '----'}
                                    className="blackinputbox"
                                  />
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        {(state?.skipreason === null &&
                          <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                            <Col md={{ span: 2 }} xs={{ span: 24 }}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: '1', paddingRight: '2px' }}>
                                  <Form.Item
                                    name='payment_image'
                                    label='Proof Image'
                                  >
                                    <Image style={{ paddingRight: '10px' }} width={100} src={proofimage} />
                                  </Form.Item>
                                </div>
                              </div>
                              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img
                                  alt='example'
                                  style={{
                                    width: '100%'
                                  }}
                                  src={proofimage}
                                />
                              </Modal>
                            </Col>
                          </Row>
                        )}


                        <Row style={{ marginBottom: '-15px', }}>
                          <Col md={24} xs={24}>
                            <Form.Item
                              name='deposit_mode'
                              label='Deposit Mode'
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                            >
                              <Controller
                                control={control}
                                name="deposit_mode"
                                render={() => (<Input value={state?.deposit_mode !== null ? depositModeName : '----'} readOnly className="blackinputbox" />)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: '-15px', }}>
                          <Col md={24} xs={24}>
                            <Form.Item
                              name='skipreason'
                              label='Skip Reason'
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                            >
                              <Select
                                placeholder='Select'
                                name='skipreason'
                                disabled
                                defaultValue={state?.skipreason !== null ? `${state?.skipreason}` : ''}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%', }} // Set the width of the Select component
                              >
                                {skipdropdown.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.def_list_code}>
                                      {item.def_list_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item name="utrNum" label="UTR No."
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                              <Input
                                onChange={getUtrNum}
                                value={UtrNum}
                                defaultValue={state?.utr_num}
                                style={{
                                  textAlign: 'center',
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>


                        <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item
                              name='Remark'
                              label='Reject Remark'
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                            >
                              <Select
                                placeholder='Select'
                                name='Remark'
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }} // Set the width of the Select component
                                value={rejectReason} // Set the selected value here
                                disabled={ClosureStatus === 8}
                                defaultValue={state?.reject !== null ? `${state?.reject}` : ''}
                                onChange={(value) => setrejectReason(value)} // Update the state when the value changes
                              >
                                {rejectdropdown.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.def_list_code}>
                                      {item.def_list_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        {(rejectReason.includes("1") &&
                          <>
                            <Row style={{ marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item name="actDeposit" label="Actual Deposit "
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Input
                                    onChange={getDepositAmt}
                                    value={DepositAmt}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item name="Diff Amt" label="Difference Amount "
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="Diff Amt"
                                    render={() => (
                                      <Input
                                        name="Diff Amt"
                                        value={enterDiffAmt}
                                        style={{
                                          textAlign: 'center',
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        )}

                      </Col>

                    </Row>
                    {(revisionData == '' &&
                      <Row justify="space-between">
                        <Col md={{ span: 8 }} xs={{ span: 24 }} justify="left">
                          <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                              <Button
                                onClick={onClickBack}
                                style={{ backgroundColor: '#f5a60b', color: 'white', fontWeight: 'bold' }}
                              >
                                {"Back"}
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>

                        {rejectReason.length === 1 && (
                          <Col md={{ span: 8 }} xs={{ span: 24 }} justify="center">
                            <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button name="Revision" disabled={rejectReason.length === 0}
                                  onClick={handleSaveRejectionData} style={{ backgroundColor: "red", color: 'white', fontWeight: 'bold' }}>
                                  {"Reject"}
                                </Button>
                              </div>
                            </Form.Item>
                          </Col>
                        )}

                        {rejectReason.length === 0 && (
                          <Col md={{ span: 8 }} xs={{ span: 24 }} justify="center">
                            <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button name="Verified" onClick={handleSaveVerificationData} style={{ backgroundColor: "green", color: 'white', fontWeight: 'bold' }}>
                                  {"Verify"}
                                </Button>
                              </div>
                            </Form.Item>
                          </Col>
                        )}



                      </Row>
                    )}
                    <br />

                    {(revisionData != '' &&
                      <>
                        <Row style={{ justifyContent: 'right', marginBottom: '15px' }}>
                          <Col md={12} xs={24} style={{ textAlign: 'center' }}>Deposit Payment Mismatch</Col>
                        </Row>
                        <Row style={{ justifyContent: 'right' }}>

                          <Col md={12} xs={24}>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="mismatch_date"
                                  label="Deposit Rejected For"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="mismatch_date"
                                    render={() => (
                                      <Input
                                        name="mismatch_date"
                                        defaultValue={WrongDepositDate}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="actual_Amount"
                                  label="Actual Amount"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="actual_Amount"
                                    render={() => (
                                      <Input
                                        name="actual_Amount"
                                        defaultValue={ActAmt}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="difference_Amount"
                                  label="Difference Amount"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="difference_Amount"
                                    render={() => (
                                      <Input
                                        name="difference_Amount"
                                        defaultValue={DiffAmt}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            {revisionData.mismatch_amt <= 0 && (
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name="2deposit_Amount"
                                    label="Deposit Amount"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Controller
                                      style={{ textAlign: 'center' }}
                                      control={control}
                                      name="2deposit_Amount"
                                      render={() => (
                                        <Input
                                          name="2deposit_Amount"
                                          defaultValue={misDeposit}
                                          style={{
                                            textAlign: 'center',
                                            color: 'black'
                                          }}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}

                            {(revisionData.mismatch_amt >= 0 &&
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name="2received_Amount"
                                    label="Received Amount"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Controller
                                      style={{ textAlign: 'center' }}
                                      control={control}
                                      name="2received_Amount"
                                      render={() => (
                                        <Input
                                          name="2received_Amount"
                                          disabled
                                          defaultValue={misDeposit}
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={{ span: 2 }} xs={{ span: 24 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div style={{ flex: '1', paddingRight: '2px' }}>
                                    <Form.Item
                                      name='payment_image'
                                      label='Proof Image'
                                    >
                                      <Image style={{ paddingRight: '10px' }} width={100} src={proofimage2} />
                                    </Form.Item>
                                  </div>
                                </div>
                                <Modal open={previewOpen2} title={previewTitle2} footer={null} onCancel={handleCancel2}>
                                  <img
                                    alt='example'
                                    style={{
                                      width: '100%'
                                    }}
                                    src={proofimage2}
                                  />
                                </Modal>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item name='2refnum' label="Reference No."
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Input
                                    name='2refnum'
                                    value={refNum1}
                                    readOnly
                                    defaultValue={'-'}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '-15px', }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name='2deposit_mode'
                                  label='Deposit Mode'
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Select
                                    placeholder='Select'
                                    name='2deposit_mode'
                                    disabled={ClosureStatus === 7 }
                                    defaultValue={ReDepositmode !== null ? `${ReDepositmode}` : ''}
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }} // Set the width of the Select component
                                  >
                                    {dropdownOptions.map((item, index) => {
                                      return (
                                        <Option key={index} value={item.def_list_code}>
                                          {item.def_list_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '-15px' }}>
                          <Col md={24} xs={24}>
                            <Form.Item name="remarks" label="Remarks"
                              labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                              <Input
                              onChange={getRemarks}
                                value={revRemarks}
                                style={{
                                  textAlign: 'center',
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>


                            <Row justify="space-between">
                              <Col md={{ span: 8 }} xs={{ span: 24 }} justify="left">
                                <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                                    <Button
                                      onClick={onClickBack}
                                      style={{ backgroundColor: '#f5a60b', color: 'white', fontWeight: 'bold' }}
                                    >
                                      {"Back"}
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Col>
                              <Col md={{ span: 8 }} xs={{ span: 24 }} justify="right">
                                <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                                    <Button name="Verify2"
                                      onClick={mismatchDepositData} style={{ backgroundColor: "green", color: 'white', fontWeight: 'bold' }}>
                                      {"Verify"}
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Col>
                            </Row>


                          </Col>

                        </Row>
                      </>
                    )}
                  </div>



                </Form >
              </Col >
            </Row >
          </Card >
        </div >
      </div >

    </>
  );
}

export default CashHandlingForm;