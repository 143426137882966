/* eslint-disable no-unused-labels */
import React, { useEffect, useState } from "react";
import { Spin, Input, Card, Button, Col, Row, Form, Select, Radio, Upload, Modal, Image, message, } from "antd";
// import messageToast from '../../../components/messageToast/messageToast';
import { useDispatch, useSelector } from "react-redux";
// import { getdayclosureDetails } from '../../../@app/entry/entrySlice';
import { last, } from 'ramda';
import { useLocation, useNavigate } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import { baseURL } from '../../../api/baseURL';
import { PlusOutlined } from '@ant-design/icons';
import { getDenominationName, update_dayclosure_details, update_mismatch_details, get_rista_Sales_Details, get_Sales_Details, get_Crew_Details, get_Revision_Details, get_Outlet_Name, get_Attachement_Details } from "../../../@app/master/masterSlice";
import './style.css'; // Import the CSS file
import { LoadingOutlined } from '@ant-design/icons';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { Option } = Select;


//const loginType = useSelector((state) => state.auth.type);


function CashHandlingForm({ setTopTitle }) {

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);

  const [form] = Form.useForm();
  const { control, } = useForm();
  const location = useLocation();
  const { state } = location;
  let defaultValue = state?.data;
  const navigate = useNavigate();

  //for geting value from previous page
  const dataValue = state ? state : {}; // Default to an empty object if state is undefined
  const ClosureStatus = dataValue.status;
  const Closureid = dataValue.closure_id;
  const DepositReason = dataValue.skipreason;
  const DepositMode = dataValue.deposit_mode;
  const Holiday = dataValue.reason;
  const parsedDenominations = JSON.parse(state?.denominations);

  if (ClosureStatus === 1 || ClosureStatus === 2 || ClosureStatus === 3) {
    setTopTitle('Cash Denomination Information');
  } else {
    setTopTitle('Cash Deposit Information');
  }

  const [denomination, setDenomination] = useState(!(ClosureStatus === 1 || ClosureStatus === 2 || ClosureStatus === 3));
  const [denominationreason, setDenominationreason] = useState(!(ClosureStatus === 1 || ClosureStatus === 2 || ClosureStatus === 3));

  const leaveHandle = () => {
    setDenomination(false);
    setDenominationreason(true);
  };


  const inactiveClass = DepositReason !== null ? 'in-active' : 'active';

  const [deposit, setDeposit] = useState(DepositReason === null ? false : true);
  const [reason, setReason] = useState(DepositReason === null ? true : false);

  // Watch for changes in DepositReason and update state variables
  useEffect(() => {
    if (DepositReason === null) {
      setDeposit(false);
      setReason(true);
    } else {
      setDeposit(true);
      setReason(false);
    }
  }, [DepositReason]);

  const depositHandle = (e) => {
    if (e.target.name === 'Deposit') {
      if (e.target.value === 0) {
        setDeposit(true);
        setReason(false);
      } else {
        setDeposit(false);
        setReason(true);
      }
    }
  };


  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const delay = [1, 2, 3, 4, 10].includes(ClosureStatus) ? 2000 : 7000;
    const timeoutId = setTimeout(() => {
      setShowLoader(false);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [ClosureStatus]);

  const [revRemarks, setrevRemarks] = useState('');
  const getRemarks = (i) => { setrevRemarks(i.target.value) };

  const [aov, setaov] = useState('');
  const getAov = (i) => { setaov(i.target.value) }
  const [abc, setabc] = useState('');
  const getAbc = (i) => { setabc(i.target.value) }
  const [remarks, setremarks] = useState('');
  const getGenRemarks = (i) => { setremarks(i.target.value) }


  const [proofimage, setproofimage] = useState('');
  const [proofimage2, setproofimage2] = useState('');
  useEffect(() => {
    dispatch(get_Attachement_Details({ data: { closureid: state?.closure_id } }))
      .then((resData) => {
        if (resData.data) {
          setproofimage(resData.data.proofimage);
          setproofimage2(resData.data.reattachimage);
        }
      })

  }, [dispatch]);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [skipdropdown, setskipdropdown] = useState([]);
  const [denominationvalue, setdenominationvalue] = useState([]);

  useEffect(() => {
    dispatch(getDenominationName())
      .then((result) => {
        if (result && Array.isArray(result.data)) {
          const defTitle1Objects = result.data.filter((item) => item.def_title_id === 1);
          const defTitle3Objects = result.data.filter((item) => item.def_title_id === 3);
          const defTitle4Objects = result.data.filter((item) => item.def_title_id === 4);
          setdenominationvalue(defTitle1Objects); // Set for def_title_id 1
          setskipdropdown(defTitle4Objects);
          setDropdownOptions(defTitle3Objects);
        }
      });
  }, [dispatch]);


  function useMapping(dispatch, getDataFunction, id) {
    const [mapping, setMapping] = useState({});
    useEffect(() => {
      dispatch(getDataFunction())
        .then((result) => {
          const data = result.data;
          if (typeof data === 'object' && Object.keys(data).length > 0) {
            const formattedData = {};
            for (const key in data) {
              if (data[key] !== undefined) {
                formattedData[key] = data[key].name;
              }
            }
            setMapping(formattedData);
          }
        });
    }, [dispatch]);

    return mapping[id];
  }
  // const orlName = useMapping(dispatch, getEmployeeMaster, state?.orl_id);
  const OutletName = useMapping(dispatch, get_Outlet_Name, state?.outlet_code);



  const [depositmodeVal, setdepositmodeVal] = useState('');
  const [redepositmodeVal, setredepositmodeVal] = useState('');

  const handleSelectChange = (value) => {
    setdepositmodeVal(value);
  };
  const handleSelectMode = (value) => {
    setredepositmodeVal(value);
  };

  const [depositSkipReason, setdepositSkipReason] = useState('');

  const antIcon = (<LoadingOutlined style={{ fontSize: 34, }} spin />);

  const [lastclosuredate, setlastclosuredate] = useState('-');

  useEffect(() => {
    dispatch(get_Sales_Details({ data: { outlet_code: state?.outlet_code, closuredate: state?.sales_closure_date } }))
      .then((response) => {
        if (response && response.data.lastclosuredate) {
          setlastclosuredate(response.data.lastclosuredate);
        } else {
          setlastclosuredate('-'); // Set a default value or handle it as needed
        }
      })
  }, [dispatch]);

  let userid;
  let outletid;

  const loginType = useSelector((state) => state.auth.type);
  if (loginType === 1) {
    userid = 0;
    outletid = '';
  } else {
    const empMap = useSelector(
      (state) => state.auth.userData.data && state.auth.userData.data.employee_mapping
    );

    userid = userData.data.id;
    if ([138, 143, 121].includes(userid)) {
      outletid = '';
    } else {
      outletid = empMap.outlet[0].id;
    }
  }

  const [crewName, setcrewName] = useState([]);

  const fetchCrewDetails = async () => {
    try {
      const result = await dispatch(get_Crew_Details({ data: { outletid: outletid } }));

      if (result && result.data && Array.isArray(result.data)) {
        setcrewName(result.data);
      } else {
        message.error({content:'Data received from the API is not in the expected format'});
      }
    } catch (error) {
      message.error({content:'Error in the API call: '});
    }
  };

  useEffect(() => {
    fetchCrewDetails();
  }, [dispatch, outletid]);

  const [revisionData, setrevisionData] = useState('');
  const [ActAmt, setActAmt] = useState('');
  const [DiffAmt, setDiffAmt] = useState('');
  const [misDeposit, setmisDeposit] = useState('');
  const [revClosureid, setrevClosureid] = useState('');

  useEffect(() => {
    setShowLoader(true);
    const outlet_code = state?.outlet_code;
    dispatch(get_Revision_Details({ data: { outlet_code: outlet_code } }))
      .then((revData) => {
        if (revData && Array.isArray(revData.data) && revData.data.length > 0) {
          const firstRevision = revData.data[0];
          setrevisionData(firstRevision);
          setActAmt(firstRevision.actual_deposit_amt);
          setDiffAmt(firstRevision.mismatch_amt);
          setrevClosureid(firstRevision.sales_closure_id);
          setrefNumVal2(firstRevision.reference_num);
          setmisDeposit(parseFloat(firstRevision.system_deposit_amt) - parseFloat(firstRevision.actual_deposit_amt));
        }
      })
    setShowLoader(false);
  }, [dispatch]);

  const parts = revClosureid.split('-'); // Split the string by '-'
  const reorderedParts = parts.slice(1, 4).reverse(); // Rearrange and reverse the relevant parts
  const revDate = reorderedParts.join('-'); // Join the parts back together with '-'

  const [loginId, setloginId] = useState('');

  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageUpdated, setImageUpdated] = useState(false);
  const handleCancel = () => setPreviewOpen(false);

  const handleChange = (e) => {
    setFileList(e?.fileList);
    setImage(e?.file?.response?.filename ?? '');
    form.setFieldsValue({ depositproof: e?.file?.response?.filename ?? '' });
    setImageUpdated(true);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(proofimage);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const props = {
    name: 'image',
    action: `${baseURL}mismatchproof-upload`,
    headers: {
      authorization: 'authorization-text'
    }
  };

  const uploadButton = (
    <Button style={{ display: 'flex', direction: 'row' }} icon={<PlusOutlined style={{ marginTop: '3px', marginRight: '4px' }} />}>
      <div
        style={{
          marginLeft: '3px'
        }}>
        {defaultValue?.id ? 'Update Image' : 'Upload'}
      </div>
    </Button>
  );

  form.setFieldsValue({ depositproof: state?.closureid });

  const [previewOpen2, setPreviewOpen2] = useState(false);
  const [fileList2, setFileList2] = useState([]);
  const [image2, setImage2] = useState('');
  const [previewImage2, setPreviewImage2] = useState('');
  const [previewTitle2, setPreviewTitle2] = useState('');
  const [imageUpdated2, setImageUpdated2] = useState(false);
  const handleCancel2 = () => setPreviewOpen2(false);

  const handleChange2 = (e) => {
    setFileList2(e?.fileList);
    setImage2(e?.file?.response?.filename ?? '2');
    form.setFieldsValue({ attchment: e?.file?.response?.filename ?? '' });
    setImageUpdated2(true);
  };
  const handlePreview2 = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage2('');
    setPreviewOpen2(true);
    setPreviewTitle2(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const props2 = {
    name: 'image',
    action: `${baseURL}mismatchproof-upload`,
    headers: {
      authorization: 'authorization-text'
    }
  };

  const uploadButton2 = (
    <Button style={{ display: 'flex', direction: 'row' }} icon={<PlusOutlined style={{ marginTop: '3px', marginRight: '4px' }} />}>
      <div
        style={{
          marginLeft: '3px'
        }}>
        {defaultValue?.id ? 'Update Proof' : 'Upload'}
      </div>
    </Button>
  );

  form.setFieldsValue({ attchment: state?.closureid });

  // Function to handle input changes
  function handleInputChange(numericValue, index, item) {
    const updatedDenominationData = [...denominationvalue];
    updatedDenominationData[index].value = parseInt(numericValue, 10) * parseFloat(item.def_list_name); // Use parseInt to convert to a number
    updatedDenominationData[index].inputvalue = numericValue;
    setdenominationvalue(updatedDenominationData);
  }

  // Calculate the total value
  const totalValue = denominationvalue.reduce((acc, item) => acc + (item.value || 0), 0);


  const [depositData, setdepositData] = useState({
    depositAmount: state?.deposit_amount,
    cashBalance: state?.balance_amount,
  });

  const [refNumVal1, setrefNumVal1] = useState(state?.referencenum);
  const getrefNum1 = (e) => { setrefNumVal1(e.target.value) }
  const [refNumVal2, setrefNumVal2] = useState('');
  const getrefNum2 = (e) => { setrefNumVal2(e.target.value) }

  const [salesDetails, setSalesDetails] = useState(''); // Use state to store sales details

  const [cashSales, setCashSales] = useState(state?.sales_amount);
  const [totalOutletCash, setTotalOutletCash] = useState(state?.closure_amount);
  const [error, setError] = useState(null);


  const handleGetSalesData = () => {
    setShowLoader(true);
    setDenomination(true);
    setDenominationreason(false);
    const outlet_code = state?.outlet_code;
    const closure_date = state?.sales_closure_date;

    dispatch(get_rista_Sales_Details({ data: { outlet_code, closure_date } }))
      .then((result) => {
        if (result && result.data) {
          setCashSales(result.data.cashsales);
          const saleAmt = result.data.cashsales;
          setSalesDetails(result.data.InvoiceDetails);
          setTotalOutletCash(parseInt(saleAmt) + parseInt(state?.open_balance));
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setError('Error in API call: ' + error.message);
        setShowLoader(false);
      });
  };

  const checkDepositAmt = (e) => {
    const { name, value } = e.target;
    // Calculate cash balance based on deposit amount
    if (name === 'deposit_Amount') {
      const depositAmount = parseFloat(value) || 0;
      const cashBalance = totalOutletCash - depositAmount; // You can modify the calculation logic here
      setdepositData({
        ...depositData,
        [name]: value,
        cashBalance: cashBalance.toFixed(2), // Assuming you want to format it as a number with two decimal places
      });
    }
  };

  const DepositAmtChk = () => {
    totalOutletCash
    if (totalOutletCash >= depositData.deposit_Amount + 500) {
      // message.error({ content: 'Please Check Deposit Amount ', })
    } else if (totalOutletCash <= depositData.deposit_Amount + 100) {
      // message.error({ content: 'Please Check Deposit Amount', })
    }

  }

  const handleSaveDenominationData = () => {
    if (loginId === '') {
      message.error({ content: 'Please Select Closure By' });
    } else {
      const outlet_code = state?.outlet_code;
      const denomination_value = denominationvalue;
      const total_denomination_value = totalValue;
      const cashsale_details = salesDetails;
      const open_balance = state?.open_balance;
      const closure_amount = totalOutletCash;
      const closure_by = loginId;
      const login_by = userid;
      const closure_id = Closureid;
      const cashSaleAmt = cashSales;
      const aovValue = aov; 
      const abcValue = abc; 
      const remarksValue = remarks; 
      const waiting_at = denomination ? '4' : '2';
  
      const dayClosureDetails = {
        outlet_code,
        closure_id,
        login_by,
        open_balance,
        closure_amount,
        denomination_value,
        total_denomination_value,
        closure_by,
        cashsale_details,
        waiting_at,
        cashSaleAmt,
        aov: aovValue,  
        abc: abcValue,  
        remarks: remarksValue       };
  
      dispatch(
        update_dayclosure_details({
          data: dayClosureDetails
        })
      ).then((response) => {
        if (response.status === 200) {
          navigate('/cashHandling');
          message.success({ content: 'Closure Details Added Successfully' });
        } else {
          message.error({ content: 'Closure Details Request failed' });
        }
      });
    }
  };
  

  const handleSaveDepositData = async () => {

    if (loginId === '') {
      message.error({ content: 'Please Select Deposit By', })
    } else {
      setShowLoader(true);
      const fileName = imageUpdated ? image ?? 'No image' : last(state?.image ? state?.image.split('/').pop() : 'No image');
      const closure_amount = totalOutletCash;
      const deposit_amount = depositData?.deposit_Amount; // Replace with your actual data source
      const outlet_code = state?.outlet_code; // Replace with your actual data source
      const cash_balance = depositData?.cashBalance; // Replace with your actual data source
      const deposit_mode = depositmodeVal; // Replace with your actual data source
      const skipreason = depositSkipReason; // Replace with your actual data source
      const depositDate = state?.sales_closure_date; // Replace with your actual data source
      const reference_num = refNumVal1; // Replace with your actual data source
      const salesClosure_id = Closureid; // Replace with your actual data source
      const closure_by = loginId;
      const Remarks = revRemarks;
      const closure_date = state?.sales_closure_date;
      const login_by = userid;
      const waiting_at = deposit ? '5' : '7'; // Replace with your actual condition

      dispatch(update_dayclosure_details({
        data: {
          filename: fileName, salesClosure_id,closure_date,Remarks, login_by, outlet_code, depositDate, deposit_amount, cash_balance,
          reference_num, deposit_mode, waiting_at, skipreason, closure_by, closure_amount
        },
      })).then((response) => {
        if (response.status === 200) {
          navigate('/cashHandling');
          message.success({ content: 'Deposit Details Added Successfully' });
        } else {
          message.error({ content: 'Deposit Details Request failed' });
        }
      });
      setShowLoader(false);
    }

  };


  const mismatchDepositData = async () => {
    setShowLoader(true);

    const fileName = imageUpdated2 ? image2 ?? 'No image' : last(state?.image ? state?.image.split('/').pop() : 'No image');
    const reattachRefnum = refNumVal2;
    const oldClosure_id = revClosureid;
    const deposit_mode = redepositmodeVal;
    const deposit_amount = misDeposit;
    const closure_by = loginId;
    const login_by = userid;
    const waiting_at = '7';

    try {
      // Update day closure details for non-deposit
      const response = await dispatch(update_mismatch_details({
        data: {
          fileName, reattachRefnum, login_by, oldClosure_id, deposit_amount,
          deposit_mode, waiting_at, closure_by,
        },
      }));

      if (response.status === 200) {
        setShowLoader(false);
        navigate('/cashHandling');
        message.success({ content: 'Deposit Details Added Successfully ' });
      } else {
        message.error({ content: 'Deposit Details Request Failed' });
      }
    } catch (error) {
      message.error({ content: 'An error occurred' });
    }

  };

  const onClickBack = () => {
    navigate('/cashHandling');
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getFile2 = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList2;
  };





  return (
    <>
      <div className="content-container">
        {showLoader && (
          <div className="loader-container">
            <Spin indicator={antIcon} />
          </div>
        )}
        <div className={`actual-content ${showLoader ? 'blurred' : ''}`}>

          <Card>
            <Row style={{ justifyContent: "center" }}>

              <Col span={24}>
                <Form
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  autoComplete='off'
                >

                  <div className=" justify-content-center align-items-center "    >
                    <Row >
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="outlet_name" label="Outlet Name" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}  >
                          <Controller
                            control={control}
                            name="orl_name"
                            render={() => (<Input value={OutletName} readOnly className="blackinputbox" />)}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="orl_name" label="ORL Name" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}  >
                         <Controller
                            control={control}
                            name="orl_name"
                            render={() => (
                              <Input
                                value={state?.outlet_orl_name}
                                readOnly
                                className="blackinputbox"
                              />
                            )}
                          />

                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                        <Form.Item name="" label="Pre. Acc. Close" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }} >
                          <Controller
                            control={control}
                            name=""
                            render={() => (<Input value={lastclosuredate} readOnly className="blackinputbox" />)}
                          />

                        </Form.Item>
                      </Col>
                      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center', color: 'black' }}>
                        <Form.Item name="Closure_By" label="Closure By" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center', } }} rules={[{required: true,}]} >
                          <Select
                            placeholder='Select'
                            defaultValue={state?.closureby !== null ? state?.closureby : ''}
                            disabled={ClosureStatus !== 1 && ClosureStatus !== 2 && ClosureStatus !== 3}
                            name='Closure_By'
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={loginId}
                            onChange={(value) => setloginId(value)}
                            style={{ width: '100%', color: 'black' }} // Set the width of the Select component
                          >
                            {crewName.map((item, index) => {
                              return (
                                <Option
                                  key={index} value={item.id}
                                  style={{ color: 'black' }} >
                                  {item.name}

                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                      </Col>

                    </Row>
                  </div>

                  {denomination === true && (
                    <div className="  ">
                      <Row>
                        <Col md={{ span: 6 }} xs={{ span: 24 }}  >
                          <Form.Item name="" label="Opening Balance" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                            <Controller
                              control={control}
                              name=""
                              render={() => (<Input value={state?.open_balance} readOnly className="blackinputbox" />)}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={{ span: 6 }} xs={{ span: 24 }}  >
                          <Form.Item name="" label="Cash Sales" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                            <Controller
                              control={control}
                              name=""
                              render={() => (<Input value={cashSales} readOnly className="blackinputbox" />)}
                            />
                            {error && (
                              <div className="error-message">{error}</div>
                            )}
                          </Form.Item>
                        </Col>

                        <Col md={{ span: 6 }} xs={{ span: 24 }} >
                          <Form.Item name="" label=" Total Cash" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }}>
                            <Controller
                              control={control}
                              name=""
                              render={() => (<Input value={totalOutletCash} readOnly className="blackinputbox" />)}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
                          <Form.Item name="deposit_by" label="Deposit By" labelCol={{ md: { span: 10 }, xs: { span: 24 }, style: { textAlign: 'center' } }} rules={[{required: true,}]}>
                            {error ? ( // Check if there's an error
                              <div className="error-message">{error}</div>
                            ) : (
                              <Select
                                placeholder='Select'
                                disabled={ClosureStatus !== 2 && ClosureStatus !== 5 && ClosureStatus !== 6 && ClosureStatus !== 4}
                                name='deposit_by'
                                defaultValue={state?.depositby !== null ? state?.depositby : 'Select'}
                                value={loginId}
                                onChange={(value) => setloginId(value)}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }} // Set the width of the Select component
                              >
                                {crewName.map((item, index) => {
                                  return (
                                    <Option
                                      key={index} value={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </Form.Item>

                        </Col>
                      </Row>
                    </div>
                  )}
                  {[1, 2, 3].includes(ClosureStatus) && ( //this is only visible when cosure status 1 or 2 or 3
                    <div>
                      <Row justify="space-between">
                        <Col md={{ span: 8 }} xs={{ span: 24 }} justify="left">
                          <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                              <Button

                                onClick={onClickBack}
                                style={{ backgroundColor: '#f5a60b', color: 'white', fontWeight: 'bold' }}
                              >
                                {"Back"}
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col md={{ span: 8 }} xs={{ span: 24 }} justify="center">
                          <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                onClick={leaveHandle}
                                style={{ backgroundColor: 'red', color: 'white', fontWeight: 'bold' }}
                              >
                                {"Leave"}
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col md={{ span: 8 }} xs={{ span: 24 }} justify="right">
                          <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                              <Button
                                onClick={handleGetSalesData}
                                style={{ backgroundColor: 'green', color: 'white', fontWeight: 'bold' }}
                              >
                                {"Make Day Sales Closure"}
                              </Button>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )}

                  <div>
                    <Row justify="center">
                      <Col md={11} xs={24}>
                        {denomination && (
                          <>
                            <table
                              name="denominationResult"
                              style={{ width: '100%', textAlign: 'center', margin: '0 auto' }}
                            >
                              <thead>
                                <tr>
                                  <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Denomination</td>
                                  <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Count</td>
                                  <td style={{ width: '33%', border: '2px black solid', backgroundColor: '#f5a60b', color: 'white' }}>Amount</td>
                                </tr>
                              </thead>
                              <tbody>
                                {Holiday == null && (
                                  Array.isArray(parsedDenominations) ? (
                                    parsedDenominations.map((item, index) => (
                                      <tr key={index + 1}>
                                        <td style={{ width: '33%', border: '2px black solid' }}>{item.def_list_name}</td>
                                        <td style={{ width: '33%', border: '2px black solid' }}>{item.inputvalue || 0}</td>
                                        <td style={{ width: '33%', border: '2px black solid' }}>{item.value || 0}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    denominationvalue.map((item, index) => (
                                      <tr key={index + 1}>
                                        <td style={{ width: '33%', border: '2px black solid' }}>{item.def_list_name}</td>
                                        <td style={{ width: '33%', border: '2px black solid' }}>
                                          <input
                                            type="number"
                                            style={{ width: '100%', textAlign: 'center' }}
                                            disabled={Holiday}
                                            onInput={(e) => {
                                              const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                              e.target.value = numericValue; // Update the input value with the numeric version
                                              handleInputChange(numericValue, index, item);
                                            }}
                                          />
                                        </td>
                                        <td style={{ width: '33%', border: '2px black solid' }}>{item.value || 0}</td>
                                        <td><input type="hidden" value={item.inputvalue || 0} /></td>
                                      </tr>
                                    ))
                                  )
                                )}

                                {Holiday != null && (
                                  denominationvalue.map((item, index) => (
                                    <tr key={index + 1}>
                                      <td style={{ width: '33%', border: '2px black solid' }}>{item.def_list_name}</td>
                                      <td style={{ width: '33%', border: '2px black solid' }}>
                                        <input
                                          type="number"
                                          style={{ width: '100%', textAlign: 'center' }}
                                          onInput={(e) => {
                                            const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                            e.target.value = numericValue; // Update the input value with the numeric version
                                            handleInputChange(numericValue, index, item);
                                          }}
                                        />
                                      </td>
                                      <td style={{ width: '33%', border: '2px black solid' }}>{item.value || 0}</td>
                                      <td><input type="hidden" value={item.inputvalue || 0} /></td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="2" style={{ backgroundColor: 'black', color: 'orange', fontWeight: 'bolder' }}>Total</td>
                                  <td style={{ backgroundColor: 'black', color: 'orange', fontWeight: 'bolder' }}>
                                    {Array.isArray(parsedDenominations) ? state?.closure_amount : totalValue}
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                            <br></br>
                             <Col md={24} xs={24}>
                                <Form.Item name="aov" label="AOV Remarks"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Input
                                    onChange={getAov}
                                    defaultValue={state?.aov}
                                    value={aov}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                            </Col>
                            <Col md={24} xs={24}>
                                <Form.Item name="abc" label="ABC Remarks"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Input
                                    onChange={getAbc}
                                    defaultValue={state?.abc}
                                    value={abc}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                            </Col>
                            <Col md={24} xs={24}>
                                <Form.Item name="remarks" label="Overall Remarks"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                  <Input
                                    onChange={getGenRemarks}
                                    defaultValue={state?.genremarks}
                                    value={remarks}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                            </Col>
                           
                            </>
                        )}
                        {denominationreason && [1, 2, 3].includes(ClosureStatus) && (
                          <>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Input
                                  name="denominationreason"
                                  readOnly
                                  defaultValue={'Outlet Holiday'}
                                  className="blackinputbox"
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                        {/* {Holiday && (
                          <>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>

                                <Input
                                  name="denominationreason"
                                  readOnly
                                  defaultValue={'Outlet Holiday'}
                                  className="blackinputbox"
                                />
                              </Col>
                            </Row>
                          </>
                        )} */}



                        {(denominationreason || (denomination && [1, 2, 3].includes(ClosureStatus))) && ![4, 5, 6, 7, 8, 9, 10].includes(ClosureStatus) && (
                          <div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Col md={{ span: 24 }} xs={{ span: 24 }}>
                              <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }}>
                                <div>
                                  <Button
                                    onClick={handleSaveDenominationData}
                                    disabled={!denominationreason && totalValue !== totalOutletCash && totalOutletCash > 0}
                                    style={{ backgroundColor: "#f5a60b", color: 'white', fontWeight: 'bold' }}
                                  >
                                    {"Submit"}
                                  </Button>
                                </div>
                              </Form.Item>
                            </Col>
                          </div>
                        )}
                      </Col>

                      {![1, 3].includes(ClosureStatus) && ( //if closure status having 1,2,3 means this won't show
                        <Col md={11} xs={24}>
                          <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                            <Col md={24} xs={24}>
                              <Form.Item
                                name="Deposit"
                                label="Deposit"
                                labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                              >
                                <Radio.Group name="Deposit" onChange={depositHandle} style={{ display: 'flex' }}>
                                  <Radio value={1} className='active'>
                                    Yes
                                  </Radio>
                                  <Radio value={0} className={inactiveClass}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>



                          {!deposit && ( // Render the row only if loading is false
                            <>
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name="deposit_Amount"
                                    label="Deposit Amount"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Controller
                                      style={{ textAlign: 'center' }}
                                      control={control}
                                      name="deposit_Amount"
                                      render={() => (
                                        <Input
                                          name="deposit_Amount"
                                          defaultValue={state?.deposit_amount}
                                          disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                          value={depositData.deposit_Amount}
                                          onChange={checkDepositAmt}
                                          onBlur={DepositAmtChk}
                                          style={{
                                            textAlign: 'center',
                                            color: 'black'
                                          }}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>

                              </Row>
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item name="cashBalance" label="Cash Balance"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                    <Controller
                                      style={{ textAlign: 'center' }}
                                      control={control}
                                      name="cashBalance"
                                      render={() => (
                                        <Input
                                          name="cashBalance"
                                          value={depositData.cashBalance}
                                          disabled // Disable editing of cash balance field
                                          style={{
                                            textAlign: 'center',
                                            color: 'black'
                                          }}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item name='refnum' label="Reference Num"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>

                                    <Input
                                      name='refnum'
                                      onBlur={getrefNum1}
                                      value={refNumVal1}
                                      disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                      defaultValue={state?.referencenum}
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={{ span: 16 }} xs={{ span: 24 }}>
                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: '1', paddingRight: '20px' }}>
                                      <Form.Item
                                        name='payment_image'
                                        label='Proof Image'
                                        getValueFromEvent={getFile}
                                      >
                                        {state?.id && fileList?.length === 0 ? (
                                          state?.image ? (
                                            <Image style={{ paddingRight: '10px' }} width={100} src={proofimage} />
                                          ) : (
                                            'No Image Available'
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </Form.Item>
                                    </div>
                                    <div style={{ flex: '1' }}>
                                      <Upload
                                        {...props}
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        capture='environment'
                                        accept='.png,.jpg,.jpeg'
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                      >
                                        {fileList.length >= 1 ? null : uploadButton}
                                      </Upload>
                                    </div>
                                  </div>
                                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                    <img
                                      alt='example'
                                      style={{
                                        width: '100%'
                                      }}
                                      src={previewImage}
                                    />
                                  </Modal>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '-15px', }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name='deposit_mode'
                                    label='Deposit Mode'
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Select
                                      placeholder='Select'
                                      name='deposit_mode'
                                      disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                      defaultValue={DepositMode !== null ? `${DepositMode}` : ''}
                                      filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      style={{ width: '100%' }}
                                      value={depositmodeVal}
                                      onChange={handleSelectChange}
                                    >
                                      {dropdownOptions.map((item, index) => {
                                        return (
                                          <Option
                                            key={index} value={item.def_list_code}>
                                            {item.def_list_name}
                                          </Option>
                                        );
                                      })}
                                    </Select>

                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item name="remarks" label="Remarks"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>
                                    <Input
                                      onChange={getRemarks}
                                      defaultValue={state?.remarks}
                                      disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                      value={revRemarks}
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                          {!reason && ( // Render the row only if loading is false
                            <>

                              <Row style={{ marginBottom: '-15px', }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name='skipreason'
                                    label='Skip Reason'
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Select
                                      placeholder='Select'
                                      name='skipreason'
                                      defaultValue={DepositReason !== null ? `${DepositReason}` : ''}
                                      disabled={ClosureStatus !== 2 && ClosureStatus !== 4 && ClosureStatus !== 5 && ClosureStatus !== 6}
                                      filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      style={{ width: '100%' }} // Set the width of the Select component
                                      value={depositSkipReason}
                                      onChange={(value) => setdepositSkipReason(value)} // Update the state when the value changes
                                    >
                                      {skipdropdown.map((item, index) => {
                                        return (
                                          <Option key={index} value={item.def_list_code}>
                                            {item.def_list_name}
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>

                            </>

                          )}
                          {[2, 4, 6].includes(ClosureStatus) && (
                            <div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Col md={{ span: 24 }} xs={{ span: 24 }} >
                                <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }} >
                                  <div >
                                    <Button
                                      // onClick={depositSubmit}
                                      onClick={handleSaveDepositData}
                                      style={{ backgroundColor: "#f5a60b", color: 'white', fontWeight: 'bold' }}
                                    >
                                      {"Submit"}
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Col>
                            </div>
                          )}

                        </Col>
                      )}




                    </Row>
                    <br />

                    {(revisionData != '' &&
                      <>
                        <Row style={{ justifyContent: 'right', marginBottom: '15px' }}>
                          <Col md={12} xs={24} style={{ textAlign: 'center' }}>Deposit Payment Mismatch</Col>
                        </Row>
                        <Row style={{ justifyContent: 'right' }}>

                          <Col md={12} xs={24}>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="mismatch_date"
                                  label="Deposit Mismatch On"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="mismatch_date"
                                    render={() => (
                                      <Input
                                        name="mismatch_date"
                                        defaultValue={revDate}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="actual_Amount"
                                  label="Actual Amount"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="actual_Amount"
                                    render={() => (
                                      <Input
                                        name="actual_Amount"
                                        defaultValue={ActAmt}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name="difference_Amount"
                                  label="Difference Amount"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Controller
                                    style={{ textAlign: 'center' }}
                                    control={control}
                                    name="difference_Amount"
                                    render={() => (
                                      <Input
                                        name="difference_Amount"
                                        defaultValue={DiffAmt}
                                        disabled
                                        style={{
                                          textAlign: 'center',
                                          color: 'black'
                                        }}
                                      />
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            {revisionData.mismatch_amt <= 0 && (
                              <>
                                <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                  <Col md={24} xs={24}>
                                    <Form.Item
                                      name="2deposit_Amount"
                                      label="Deposit Amount"
                                      labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                    >
                                      <Controller
                                        style={{ textAlign: 'center' }}
                                        control={control}
                                        name="2deposit_Amount"
                                        render={() => (
                                          <Input
                                            name="2deposit_Amount"
                                            disabled
                                            defaultValue={misDeposit}
                                            style={{
                                              textAlign: 'center',
                                              color: 'black'
                                            }}
                                          />
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                  <Col md={{ span: 16 }} xs={{ span: 24 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <div style={{ flex: '1', paddingRight: '20px' }}>
                                        <Form.Item
                                          name='attach_image'
                                          label='Proof Image'
                                          getValueFromEvent={getFile2}
                                        >
                                          {state?.id && fileList2?.length === 0 ? (
                                            state?.image ? (
                                              <Image style={{ paddingRight: '10px' }} width={100} src={proofimage2} />
                                            ) : (
                                              'No Image Available'
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </Form.Item>
                                      </div>
                                      <div style={{ flex: '1' }}>
                                        <Upload
                                          {...props2}
                                          fileList={fileList2}
                                          onPreview={handlePreview2}
                                          capture='environment'
                                          accept='.png,.jpg,.jpeg'
                                          onChange={(e) => {
                                            handleChange2(e);
                                          }}>
                                          {fileList2.length >= 1 ? null : uploadButton2}
                                        </Upload>
                                      </div>
                                    </div>
                                    <Modal open={previewOpen2} title={previewTitle2} footer={null} onCancel={handleCancel2}>
                                      <img
                                        alt='example'
                                        style={{
                                          width: '100%'
                                        }}
                                        src={previewImage2}
                                      />
                                    </Modal>
                                  </Col>
                                </Row>
                              </>
                            )}

                            {(revisionData.mismatch_amt >= 0 &&
                              <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                                <Col md={24} xs={24}>
                                  <Form.Item
                                    name="2received_Amount"
                                    label="Mismatch Amount"
                                    labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                  >
                                    <Controller
                                      style={{ textAlign: 'center' }}
                                      control={control}
                                      name="2received_Amount"
                                      render={() => (
                                        <Input
                                          name="2received_Amount"
                                          defaultValue={misDeposit}
                                          disabled
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}

                            <Row style={{ justifyContent: 'center', marginBottom: '-15px' }}>
                              <Col md={24} xs={24}>
                                <Form.Item name='2refnum' label="Reference Num"
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}>

                                  <Input
                                    name='2refnum'
                                    onBlur={getrefNum2}
                                    value={refNumVal2}
                                    defaultValue={''}
                                    style={{
                                      textAlign: 'center',
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '-15px', }}>
                              <Col md={24} xs={24}>
                                <Form.Item
                                  name='2deposit_mode'
                                  label='Deposit Mode'
                                  labelCol={{ md: { span: 12 }, xs: { span: 12 }, style: { textAlign: 'center' } }}
                                >
                                  <Select
                                    placeholder='Select'
                                    name='2deposit_mode'
                                    value={redepositmodeVal}
                                    onChange={handleSelectMode}
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }} // Set the width of the Select component
                                  >
                                    {dropdownOptions.map((item, index) => {
                                      return (
                                        <Option key={index} value={item.def_list_code}>
                                          {item.def_list_name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <div style={{ paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Col md={{ span: 24 }} xs={{ span: 24 }} >
                                <Form.Item labelCol={{ md: { span: 24 }, xs: { span: 24 } }} >
                                  <div >
                                    <Button
                                      onClick={mismatchDepositData}
                                      style={{ backgroundColor: "#f5a60b", color: 'white', fontWeight: 'bold' }}
                                    >
                                      {"Submit"}
                                    </Button>
                                  </div>
                                </Form.Item>
                              </Col>
                            </div>

                          </Col>

                        </Row>
                      </>
                    )}
                  </div>



                </Form >
              </Col >
            </Row >
          </Card >
        </div >
      </div >

    </>
  );
}

export default CashHandlingForm;
