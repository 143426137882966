
import { format } from 'date-fns';

function getEntryDate(params) {
  return `${format(new Date(params.row.tran_date), 'dd-MM-yyyy')}`;
}	

function getSettlementDate(params) {
  return `${format(new Date(params.row.settlement_date), 'dd-MM-yyyy')}`;
}	

export const column = [
  { key: '1', headerName: 'S.No', field: 'id', hide: false, width: 100 },
  { key: '2', headerName: 'Outlet Code', field: 'outlet_code', hide: false, width: 100 },
  { key: '3', headerName: 'Outlet Name', field: 'outlet_name', hide: false, width: 270}, 
  { key: '4', headerName: 'Trans Date', field: 'tran_date', hide: false, width: 140,
    valueGetter: getEntryDate },
  { key: '5', headerName: 'Settlement Date', field: 'settlement_date', hide: false, width: 140,
    valueGetter: getSettlementDate },
  { key: '6', headerName: 'Zomato Id', field: 'zomato_id', hide: false, width: 180 }, 
  { key: '7', headerName: 'Order Id', field: 'order_id', hide: false, width: 180 },
  { key: '8', headerName: 'UTR', field: 'utr', hide: false, width: 180 },
  { key: '9', headerName: 'Service Fees', field: 'service_fees', hide: false, width: 180 },
  { key: '10', headerName: 'Taxes on Service Payment_mechanisum_fees', field: 'taxes_on_service_payment_mechanisum_fees', hide: false, width: 180 },
  { key: '11', headerName: 'TCS Amt', field: 'tcs_amt', hide: false, width: 90 },
  { key: '12', headerName: 'TDS Amt', field: 'tds_amt', hide: false, width: 90 },
  { key: '13', headerName: 'Order Status', field: 'order_status', hide: false, width: 180 },
  { key: '14', headerName: 'Gross Amt', field: 'gross_amt', hide: false, width: 150 },
];
